import {
  AGREEMENT_MODE_CONSTRUCTION_WORKS,
  AGREEMENT_MODE_DELIVERIES,
  AGREEMENT_MODE_ORDER_FORM,
  AGREEMENT_MODE_SERVICES,
  AGREEMENT_TYPE_OUTSIDE_ORDER,
  AGREEMENT_TYPE_PROJECT,
  AGREEMENT_TYPE_SUPPLEMENT,
  AgreementMode,
  AgreementType
} from 'modules/Agreements/type';
import {
  AGREEMENT_STAGE_AGREEMENT,
  AGREEMENT_STAGE_PREPARING,
  AGREEMENT_STEP_CONTRACTOR_EXECUTOR,
  AGREEMENT_STEP_AGREEMENT_DATE,
  AGREEMENT_STEP_PREAMBLE,
  AGREEMENT_STEP_SUBJECT,
  AGREEMENT_STEP_SUBJECT_ORDER_FORM,
  AGREEMENT_STEP_SUPPLEMENTARY_ORDERS,
  AGREEMENT_STEP_OPTION_RIGHT,
  AGREEMENT_STEP_DEADLINE,
  AGREEMENT_STEP_TYPE,
  AGREEMENT_STEP_WORK_REQUIREMENTS,
  AGREEMENT_STEP_CONTRACTOR_DUTIES,
  AGREEMENT_STEP_EXECUTOR_DUTIES,
  AGREEMENT_STEP_REALIZATION_PEOPLE,
  AGREEMENT_STEP_REWARD,
  AGREEMENT_STEP_PERSONAL_DATA_PROTECTION,
  AGREEMENT_STEP_DISPUTES,
  AGREEMENT_STEP_FINAL_PROVISIONS,
  AGREEMENT_STEP_SUBEXECUTORS,
  AGREEMENT_STEP_EMPLOYMENT_RELATIONSHIP,
  AGREEMENT_STEP_ACCEPT,
  AGREEMENT_STEP_PERFORMANCE_SECURITY,
  AGREEMENT_STEP_INSURANCE,
  AGREEMENT_STEP_RENOUNCEMENT,
  AGREEMENT_STEP_CONTRACT_CHANGES,
  AGREEMENT_STEP_INDEXATION_CLAUSES,
  AGREEMENT_STEP_REPRESENTATIVES,
  AGREEMENT_STEP_REPLACEMENT,
  AGREEMENT_STEP_LICENSE,
  AGREEMENT_STEP_QUALITY_GUARANTEE,
  AGREEMENT_STEP_PENALTIES,
  AGREEMENT_STEP_DELIVERY_REQUIREMENTS,
  AGREEMENT_STEP_RECEIPT,
  AGREEMENT_STEP_BASIC_INFORMATIONS,
  AGREEMENT_STEP_ADDITIONAL_PROVISIONS,
  AGREEMENT_STEP_SPECIFIC_REQUIREMENTS,
  AgreementStageSlug,
  AgreementStepSlug,
  AGREEMENT_STEP_PURPOSE,
  AGREEMENT_STEP_CO_FINANCING,
  AGREEMENT_STEP_CONTRACT_SING,
  AGREEMENT_STEP_SIGNATURE_VERIFICATION
} from 'modules/Agreements/step';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

export const getStageName = (stage: AgreementStageSlug): string => {
  switch (stage) {
    case AGREEMENT_STAGE_PREPARING: {
      return 'Przygotowanie umowy';
    }

    case AGREEMENT_STAGE_AGREEMENT: {
      return 'Umowa';
    }

    default: {
      return stage;
    }
  }
};

export const getStepName = (step: AgreementStepSlug, mode?: AgreementMode): string => {
  switch (step) {
    case AGREEMENT_STEP_TYPE: {
      return 'Rodzaj umowy';
    }

    case AGREEMENT_STEP_PURPOSE: {
      return 'Cel';
    }

    case AGREEMENT_STEP_AGREEMENT_DATE: {
      return 'Data zawarcia umowy';
    }

    case AGREEMENT_STEP_CONTRACTOR_EXECUTOR: {
      return 'Oznaczenie stron';
    }

    case AGREEMENT_STEP_CO_FINANCING: {
      return 'Dofinansowanie ze środków zewnętrznych';
    }

    case AGREEMENT_STEP_PREAMBLE: {
      return 'Preambuła';
    }

    case AGREEMENT_STEP_CONTRACT_SING: {
      return 'Podpisanie umowy';
    }

    case AGREEMENT_STEP_SIGNATURE_VERIFICATION: {
      return 'Weryfikacja podpisu';
    }

    case AGREEMENT_STEP_SUBJECT: {
      return 'Przedmiot umowy';
    }

    case AGREEMENT_STEP_SUBJECT_ORDER_FORM: {
      return 'Przedmiot zamówienia';
    }

    case AGREEMENT_STEP_SUPPLEMENTARY_ORDERS: {
      if (mode === AGREEMENT_MODE_DELIVERIES) {
        return 'Zamówienia na dodatkowe dostawy';
      }
      return 'Zamówienia uzupełniające';
    }

    case AGREEMENT_STEP_OPTION_RIGHT: {
      return 'Prawo opcji';
    }

    case AGREEMENT_STEP_DEADLINE: {
      return 'Termin wykonania umowy';
    }

    case AGREEMENT_STEP_WORK_REQUIREMENTS: {
      return 'Wymagania dotyczące robót, materiałów, urządzeń i instalacji';
    }

    case AGREEMENT_STEP_CONTRACTOR_DUTIES: {
      return 'Obowiązki zamawiającego';
    }

    case AGREEMENT_STEP_EXECUTOR_DUTIES: {
      return 'Obowiązki wykonawcy';
    }

    case AGREEMENT_STEP_REALIZATION_PEOPLE: {
      return 'Osoby realizujące zamówienie';
    }

    case AGREEMENT_STEP_REWARD: {
      return 'Wynagrodzenie';
    }

    case AGREEMENT_STEP_SUBEXECUTORS: {
      return 'Podwykonawcy';
    }

    case AGREEMENT_STEP_EMPLOYMENT_RELATIONSHIP: {
      return 'Obowiązek zatrudniania na podstawie stosunku pracy';
    }

    case AGREEMENT_STEP_ACCEPT: {
      if (mode === AGREEMENT_MODE_SERVICES) {
        return 'Odbiór, warunki i miejsce spełnienia świadczenia';
      }
      return 'Odbiór robót';
    }

    case AGREEMENT_STEP_PERFORMANCE_SECURITY: {
      return 'Zabezpieczenie należytego wykonania umowy';
    }

    case AGREEMENT_STEP_INSURANCE: {
      return 'Ubezpieczenie';
    }

    case AGREEMENT_STEP_QUALITY_GUARANTEE: {
      return 'Gwarancja jakości i rękojmia';
    }

    case AGREEMENT_STEP_PENALTIES: {
      return 'Kary umowne';
    }

    case AGREEMENT_STEP_RENOUNCEMENT: {
      return 'Odstąpienie';
    }

    case AGREEMENT_STEP_CONTRACT_CHANGES: {
      return 'Zmiana treści umowy';
    }

    case AGREEMENT_STEP_INDEXATION_CLAUSES: {
      return 'Klauzule waloryzacyjne';
    }

    case AGREEMENT_STEP_REPRESENTATIVES: {
      return 'Przedstawiciele';
    }

    case AGREEMENT_STEP_REPLACEMENT: {
      return 'Zastępstwo inwestycyjne';
    }

    case AGREEMENT_STEP_LICENSE: {
      return 'Prawa autorskie/licencja';
    }

    case AGREEMENT_STEP_PERSONAL_DATA_PROTECTION: {
      return 'Ochrona danych osobowych';
    }

    case AGREEMENT_STEP_DISPUTES: {
      return 'Polubowne rozstrzyganie sporów';
    }

    case AGREEMENT_STEP_FINAL_PROVISIONS: {
      return 'Postanowienia końcowe';
    }

    case AGREEMENT_STEP_DELIVERY_REQUIREMENTS: {
      return 'Wymagania dotyczące dostaw';
    }

    case AGREEMENT_STEP_RECEIPT: {
      return 'Odbiór, warunki i miejsce spełnienia świadczenia';
    }

    case AGREEMENT_STEP_BASIC_INFORMATIONS: {
      return 'Dane podstawowe';
    }

    case AGREEMENT_STEP_ADDITIONAL_PROVISIONS: {
      return 'Postanowienia dodatkowe';
    }

    case AGREEMENT_STEP_SPECIFIC_REQUIREMENTS: {
      return 'Wymogi szczegółowe co do usługi';
    }

    default: {
      return step;
    }
  }
};

export const getAgreementModeName = (mode: AgreementMode): string => {
  switch (mode) {
    case AGREEMENT_MODE_CONSTRUCTION_WORKS: {
      return 'Umowa o roboty budowlane';
    }

    case AGREEMENT_MODE_DELIVERIES: {
      return 'Umowa o dostawy';
    }

    case AGREEMENT_MODE_SERVICES: {
      return 'Umowa o świadczenie usług';
    }

    case AGREEMENT_MODE_ORDER_FORM: {
      return 'Formularz zamówienia';
    }

    default: {
      return mode;
    }
  }
};

export const getAgreementTypeName = (type: AgreementType): string => {
  switch (type) {
    case AGREEMENT_TYPE_PROJECT: {
      return 'Umowa do postępowania o udzielenie zamówienia w ramach p.z.p.';
    }

    case AGREEMENT_TYPE_OUTSIDE_ORDER: {
      return 'Umowa poza postępowaniem o udzielenie zamówienia publicznego w ramach p.z.p';
    }

    case AGREEMENT_TYPE_SUPPLEMENT: {
      return 'Uzupełnienie umowy po wyborze oferty w postępowaniu o udzielenie zamówienia publicznego w ramach p.z.p';
    }

    default: {
      return type;
    }
  }
};

export const PREVIEW_PLACEHOLDER = '................';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getStepValues = <V>(data: any, step: AgreementStepSlug): V => {
  return data[step] as V;
};

export const getFieldForPreview = <V = any>(
  step: any,
  modifiedStep: any,
  key: string
): { value: V; modified: boolean } => {
  const savedValue = _get(step, key, null);
  const value = _get(modifiedStep, key, null);

  return {
    value: (modifiedStep === null ? savedValue : value) as V,
    modified: modifiedStep === null ? false : !_isEqual(savedValue, value)
  };
};
