import { AGREEMENT_MODE_ORDER_FORM, AgreementFormConfiguration } from 'modules/Agreements/type';
import AgreementForm from 'modules/Agreements/components/Form';
import React from 'react';
import AgreementStepType from 'modules/Agreements/components/Steps/Default/Type';
import {
  AGREEMENT_STAGE_AGREEMENT,
  AGREEMENT_STAGE_PREPARING,
  AGREEMENT_STEP_BASIC_INFORMATIONS,
  AGREEMENT_STEP_CONTRACTOR_EXECUTOR,
  AGREEMENT_STEP_SUBJECT_ORDER_FORM,
  AGREEMENT_STEP_REWARD,
  AGREEMENT_STEP_TYPE,
  AGREEMENT_STEP_PURPOSE,
  AGREEMENT_STEP_CONTRACT_SING,
  AGREEMENT_STEP_SIGNATURE_VERIFICATION
} from 'modules/Agreements/step';
import AgreementStepBasicInformations from 'modules/Agreements/components/Steps/OrderForm/BasicInformations';
import AgreementStepPreviewBasicInformations from 'modules/Agreements/components/Preview/OrderForm/BasicInformations';
import AgreementStepContractorExecutor from 'modules/Agreements/components/Steps/OrderForm/ContractorExecutor';
import AgreementStepSubject from 'modules/Agreements/components/Steps/OrderForm/Subject';
import AgreementStepReward from 'modules/Agreements/components/Steps/OrderForm/Reward';
import AgreementStepPurpose from 'modules/Agreements/components/Steps/Default/Purpose';
import AgreementStepSignatureVerification from 'modules/Agreements/components/Steps/Default/SignatureVerification';
import AgreementStepContractSing from '../../../components/Steps/Default/ContractSing';

const AgreementUpdateOrderFormView = (): JSX.Element => {
  const configuration: AgreementFormConfiguration = {
    stages: [
      {
        slug: AGREEMENT_STAGE_PREPARING,
        steps: [
          {
            slug: AGREEMENT_STEP_TYPE,
            component: AgreementStepType
          }
        ],
        preview: false
      },
      {
        slug: AGREEMENT_STAGE_AGREEMENT,
        steps: [
          {
            slug: AGREEMENT_STEP_PURPOSE,
            component: AgreementStepPurpose
          },
          {
            slug: AGREEMENT_STEP_BASIC_INFORMATIONS,
            component: AgreementStepBasicInformations,
            previewComponent: AgreementStepPreviewBasicInformations
          },
          {
            slug: AGREEMENT_STEP_CONTRACTOR_EXECUTOR,
            component: AgreementStepContractorExecutor,
            previewComponent: null
          },
          {
            slug: AGREEMENT_STEP_SUBJECT_ORDER_FORM,
            component: AgreementStepSubject,
            previewComponent: null
          },
          {
            slug: AGREEMENT_STEP_REWARD,
            component: AgreementStepReward,
            previewComponent: null
          }
        ]
      }
    ]
  };

  if (process.env.THEME_TEMPLATE === 'bs') {
    configuration.stages[1].steps.push(
      {
        slug: AGREEMENT_STEP_SIGNATURE_VERIFICATION,
        component: AgreementStepSignatureVerification,
        previewComponent: null
      },
      {
        slug: AGREEMENT_STEP_CONTRACT_SING,
        component: AgreementStepContractSing,
        previewComponent: null
      }
    );
  }

  return <AgreementForm configuration={configuration} mode={AGREEMENT_MODE_ORDER_FORM} />;
};

export default AgreementUpdateOrderFormView;
