import classNames from 'classnames';
import React from 'react';
import { Button } from 'reactstrap';

export interface FileButtonProps {
  name?: string;
  id?: string;
  label?: string;
  size?: string;
  disabled?: boolean;
  required?: boolean;
  className?: string;
  textClassName?: string;
  onChange: (files: FileList) => void;
  multiple?: boolean;
  accept?: string;
}

class FileButton extends React.Component<FileButtonProps> {
  protected readonly inputRef: React.RefObject<HTMLInputElement>;

  constructor(props: FileButtonProps) {
    super(props);

    this.inputRef = React.createRef();

    this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  onChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const { onChange } = this.props;

    const { target } = event;

    onChange(target.files);

    target.value = null;
  }

  onClick(): void {
    const { current } = this.inputRef;

    if (current) {
      current.click();
    }
  }

  render(): React.ReactNode {
    const {
      name,
      id,
      label = 'Wybierz plik',
      size = 'sm',
      disabled = false,
      required = false,
      className,
      textClassName,
      accept,
      multiple = false
    } = this.props;

    return (
      <div className={classNames('file-button position-relative', className)}>
        <Button
          color="link"
          size={size}
          className="link-with-border p-0 m-0"
          disabled={disabled}
          onClick={this.onClick}
        >
          <span className={classNames(textClassName)}>{label}</span>
        </Button>
        <input
          ref={this.inputRef}
          type="file"
          name={name}
          id={id}
          className="sr-only"
          style={{ bottom: '0', left: '50%' }}
          disabled={disabled}
          required={required}
          onChange={this.onChange}
          multiple={multiple}
          accept={accept}
        />
      </div>
    );
  }
}

export default FileButton;
