import React, { ReactNode } from 'react';
import MetisMenu from 'metismenujs';
import './style.scss';
import { Menu, MenuNested, MenuSection, MenuLink, MenuKey, MenuItems, MenuButton } from 'app/menu';
import Authorized from 'modules/Auth/container/Authorize';
import { NavLink } from 'react-router-dom';
import User from 'modules/User/model/User';
import { connect } from 'react-redux';
import { RootState } from 'app/reducer';
import ExpandIcon from 'modules/Layout/component/Icon/Expand';
import classNames from 'classnames';

export interface StateProps {
  user: User;
}

export const mapState = (state: RootState): StateProps => {
  const { user } = state.auth;

  return {
    user
  };
};

type IProps = {
  userNav: boolean;
  adminNav: boolean;
  clientNav: boolean;
  executorNav: boolean;
  officeNav: boolean;
  judgementNav: boolean;
  clauseNav: boolean;
  clientClause: boolean;
  adminPlanningProceedings: boolean;
  adminOpenProceedings: boolean;
  clientProceedings: boolean;
  clientForeignProceedings: boolean;
  agreements: boolean;
  accessibility: boolean;
};

const initState = {
  userNav: false,
  adminNav: false,
  clientNav: false,
  executorNav: false,
  officeNav: false,
  judgementNav: false,
  clauseNav: false,
  clientClause: false,
  proceedings: false,
  adminPlanningProceedings: false,
  adminOpenProceedings: false,
  clientProceedings: false,
  clientForeignProceedings: false,
  agreements: false,
  accessibility: false
};

class SidebarMenu extends React.Component<StateProps, IProps> {
  protected menu: MetisMenu;
  protected menuItems: {
    key: MenuKey;
    reactNode: ReactNode;
  }[];

  constructor(props: never) {
    super(props);

    this.menuItems = [];

    this.state = initState;
  }

  componentDidMount(): void {
    this.menu = new MetisMenu('#side-menu', {
      toggle: false
    });
  }

  // eslint-disable-next-line class-methods-use-this
  renderLink(item: MenuLink, className: string): ReactNode {
    const { title, href, icon, key, hasAccess = null, permissions = [] } = item;
    return (
      <Authorized key={key} permissions={permissions} hasAccess={hasAccess}>
        <li className={className}>
          <NavLink to={href} exact className={`side-nav-link-ref ${item.className ?? ''}`}>
            {Boolean(icon) && icon}
            <span className="white-break-spaces">{title}</span>
          </NavLink>
        </li>
      </Authorized>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderSection(item: MenuSection, className: string): ReactNode {
    const { key, title, icon, hasAccess = null, permissions = [] } = item;
    return (
      <Authorized key={key} permissions={permissions} hasAccess={hasAccess}>
        <li key={key} className={`menu-title ${item.className ?? ''} ${className}`}>
          {Boolean(icon) && icon}
          <span className="white-break-spaces">{title}</span>
        </li>
      </Authorized>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderNested(item: MenuNested, className: string): ReactNode {
    const { children, navLevel, title, icon, key, permissions = [], isActive = null, hasAccess = null } = item;
    const builtChildren = children.map((child) => this.renderMenu(child, className));
    const {
      userNav,
      adminNav,
      clientNav,
      executorNav,
      officeNav,
      judgementNav,
      clauseNav,
      clientClause,
      adminPlanningProceedings,
      adminOpenProceedings,
      clientProceedings,
      clientForeignProceedings,
      agreements,
      accessibility
    } = this.state;

    const onClick = () => {
      if (title === 'Użytkownicy') this.setState((state) => ({ ...initState, userNav: !state.userNav }));
      else if (title === 'Administratorzy') this.setState((state) => ({ ...initState, adminNav: !state.adminNav }));
      else if (title === 'Klienci') this.setState((state) => ({ ...initState, clientNav: !state.clientNav }));
      else if (title === 'Baza wykonawców')
        this.setState((state) => ({ ...initState, executorNav: !state.executorNav }));
      else if (title === 'Urzędy') this.setState((state) => ({ ...initState, officeNav: !state.officeNav }));
      else if (title === 'Orzeczenia sądowe')
        this.setState((state) => ({ ...initState, judgementNav: !state.judgementNav }));
      else if (title === 'Klauzule') this.setState((state) => ({ ...initState, clauseNav: !state.clauseNav }));
      else if (title === 'Rejestr klauzul')
        this.setState((state) => ({ ...initState, clientClause: !state.clientClause }));
      else if (title === 'Rejestr orzeczeń, komentarze')
        this.setState((state) => ({ ...initState, clientClause: !state.clientClause }));
      else if (title === 'Planowane postępowania')
        this.setState((state) => ({ ...initState, adminPlanningProceedings: !state.adminPlanningProceedings }));
      else if (title === 'Prowadzone postępowania')
        this.setState((state) => ({ ...initState, adminOpenProceedings: !state.adminOpenProceedings }));
      else if (title === 'Wyszukiwarka postępowań')
        this.setState((state) => ({ ...initState, clientProceedings: !state.clientProceedings }));
      else if (title === 'Wyszukiwarka zagranicznych przetargów')
        this.setState((state) => ({ ...initState, clientForeignProceedings: !state.clientForeignProceedings }));
      else if (title === 'Kreator umów') this.setState((state) => ({ ...initState, agreements: !state.agreements }));
      else if (title === 'Dostępność')
        this.setState((state) => ({ ...initState, accessibility: !state.accessibility }));
    };

    const isNestedActive = (): boolean => {
      if (title === 'Użytkownicy' && userNav) {
        return true;
      }
      if (title === 'Administratorzy' && adminNav) {
        return true;
      }
      if (title === 'Klienci' && clientNav) {
        return true;
      }
      if (title === 'Baza wykonawców' && executorNav) {
        return true;
      }
      if (title === 'Urzędy' && officeNav) {
        return true;
      }
      if (title === 'Orzeczenia sądowe' && judgementNav) {
        return true;
      }
      if (title === 'Klauzule' && clauseNav) {
        return true;
      }
      if (title === 'Rejestr klauzul' && clientClause) {
        return true;
      }
      if (title === 'Rejestr orzeczeń, komentarze' && clientClause) {
        return true;
      }
      if (title === 'Planowane postępowania' && adminPlanningProceedings) {
        return true;
      }
      if (title === 'Prowadzone postępowania' && adminOpenProceedings) {
        return true;
      }
      if (title === 'Wyszukiwarka postępowań' && clientProceedings) {
        return true;
      }
      if (title === 'Wyszukiwarka zagranicznych przetargów' && clientForeignProceedings) {
        return true;
      }
      if (title === 'Dostępność' && accessibility) {
        return true;
      }

      return title === 'Kreator umów' && agreements;
    };

    return (
      <Authorized key={key} permissions={permissions} hasAccess={hasAccess}>
        <li className={classNames({ 'mm-active': isNestedActive() }, className)}>
          <NavLink
            to="#"
            isActive={isActive}
            className={`waves-effect has-dropdown standard-nav-nested-link ${item.className ?? ''}`}
            aria-expanded="false"
            onClick={onClick}
          >
            {Boolean(icon) && icon}
            <span className="white-break-spaces">{title}</span>
            <span className="menu-arrow-svg">
              <ExpandIcon />
            </span>
          </NavLink>
          {/* eslint-disable-next-line jsx-a11y/role-supports-aria-props */}
          <ul
            className={classNames({ 'mm-show': isNestedActive() }, `nav-${navLevel}-level mm-collapse`)}
            aria-expanded="false"
          >
            {builtChildren}
          </ul>
        </li>
      </Authorized>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderButton(item: MenuButton, className: string): ReactNode {
    const { title, icon, key, hasAccess = null, permissions = [], onClick } = item;
    return (
      <Authorized key={key} permissions={permissions} hasAccess={hasAccess}>
        <li className={className}>
          <NavLink
            to={key}
            className={`standard-nav-nested-link ${item.className ?? ''}`}
            onClick={(event) => {
              event.preventDefault();
              onClick();
            }}
          >
            {Boolean(icon) && icon}
            <span className="white-break-spaces">{title}</span>
          </NavLink>
        </li>
      </Authorized>
    );
  }

  renderMenu(item: Menu, className = ''): ReactNode {
    switch (item.type) {
      case 'section': {
        return this.renderSection(item, className);
      }

      case 'nested': {
        return this.renderNested(item, className);
      }

      case 'link': {
        return this.renderLink(item, className);
      }

      case 'button': {
        return this.renderButton(item, className);
      }

      default: {
        return null;
      }
    }
  }

  render(): React.ReactNode {
    const menu = MenuItems.map((entry) => this.renderMenu(entry));

    return (
      <>
        <div id="sidebar-menu">
          <ul className="metismenu" id="side-menu">
            {menu}
          </ul>
        </div>
        <div className="clearfix" />
      </>
    );
  }
}

export default connect<StateProps>(mapState)(SidebarMenu);
