import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PageProps } from 'modules/Layout/type';
import { managePageAction } from 'modules/Layout/action';
// eslint-disable-next-line import/no-cycle
import { breadcrumbRouteForeignProceedingsReports } from '../../breadcrumbs';
import UNLogo from "../../component/Logos";

const ForeignProceedingsReports = () => {
  const dispatch = useDispatch();

  const managePage = (payload: PageProps) => dispatch(managePageAction(payload));

  useEffect(() => {
    managePage({
      title: 'Raporty, statystyki, prognozy',
      breadcrumb: breadcrumbRouteForeignProceedingsReports()
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="row users-view">
      <div className="col-12">
        {/*<div className="row">*/}
        {/*  <a*/}
        {/*    target="_blank"*/}
        {/*    rel="noreferrer"*/}
        {/*    style={{ display: 'block' }}*/}
        {/*    className="mb-2"*/}
        {/*    href={`${process.env.BACKEND_ENDPOINT}/reports/zamowienia_Grupy_Banku_Swiatowego.pdf`}*/}
        {/*  >*/}
        {/*    Zamówienia publiczne WBG*/}
        {/*  </a>*/}
        {/*</div>*/}
        <div className="row align-items-center">
          <a
            target="_blank"
            rel="noreferrer"
            className="m-2 document-link"
            href={`${process.env.BACKEND_ENDPOINT}/reports/zamowienia_Organizacji_Narodów_Zjednoczonych.pdf`}
          >
            Zamówienia publiczne ONZ
          </a>
          <UNLogo/>
        </div>
      </div>
    </div>
  );
};

export default ForeignProceedingsReports;
